import { ComponentPropsWithoutRef, FC } from 'react';

import { Container } from 'modules/common/ui';
import { Page } from 'modules/common/routing';

import { Section } from '../Section';

import { Cards } from './Loan.Cards';
import { Description } from './Loan.Description';
import { Card, Wrap as CardWrap } from './Card';

import telescope from './assets/telescope.png';
import telescope2x from './assets/telescope_2x.png';
import rocket from './assets/rocket.png';
import rocket2x from './assets/rocket_2x.png';
import star from './assets/star.png';
import star2x from './assets/star_2x.png';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Section>;

/**
 * Отображает блок "Выбери свой заём".
 */
export const Loan: FC<Props> = ({ ...props }) => (
  <Section title="Выбери свой заём" {...props}>
    <Container>
      <Cards>
        <CardWrap>
          <Card
            title="2 000 ₽ - 5 000 ₽"
            badge="1% в день"
            description="на 7 - 15 дней"
            image={telescope}
            image2x={telescope2x}
            href={`${Page.APPLICATION_NEW}?amount=500000&period=15`}
          />
        </CardWrap>
        <CardWrap>
          <Card
            title="5 000 ₽ - 10 000 ₽"
            badge="0% в день"
            description="на 7 - 180 дней"
            image={rocket}
            image2x={rocket2x}
            href={`${Page.APPLICATION_NEW}?amount=1000000&period=30`}
          />
        </CardWrap>
        <CardWrap>
          <Card
            title="10 000 ₽ - 30 000 ₽"
            badge="от 0% в день"
            description="на 7 - 180 дней"
            image={star}
            image2x={star2x}
            href={`${Page.APPLICATION_NEW}?amount=2000000&period=30`}
          />
        </CardWrap>
      </Cards>
      <Description>
        Сумма, срок и процентная ставка по займу зависят от условий договора
      </Description>
    </Container>
  </Section>
);
