import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './CardTwo.Root';
import { Title } from './CardTwo.Title';
import { Description } from './CardTwo.Description';
import { Image } from './CardTwo.Image';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заголовок.
   */
  title: string;

  /**
   * URL изображения.
   */
  image: string;

  /**
   * URL изображения 2х.
   */
  image2x: string;

  /**
   * Описание.
   */
  description: string;
};

/**
 * Компонент отображающий вторую карточку плюса заема.
 */
export const CardTwo: FC<Props> = ({
  title,
  image,
  image2x,
  description,
  ...props
}) => (
  <Root {...props}>
    <Title>{title}</Title>
    <Description>{description}</Description>
    <Image src={image} srcSet={`${image2x} 2x`} alt={title} />
  </Root>
);
