import { ComponentPropsWithoutRef, FC } from 'react';

import { Root } from './Card.Root';
import { Title } from './Card.Title';
import { Description } from './Card.Description';
import { Image } from './Card.Image';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заголовок.
   */
  title: string;

  /**
   * URL изображения.
   */
  image: string;

  /**
   * URL изображения 2х.
   */
  image2x: string;

  /**
   * Описание.
   */
  description: string;
};

/**
 * Компонент отображающий карточку "Как взять заем?".
 */
export const Card: FC<Props> = ({
  title,
  image,
  image2x,
  description,
  ...props
}) => (
  <Root {...props}>
    <Image src={image} srcSet={`${image2x} 2x`} alt={title} />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Root>
);
