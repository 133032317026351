import { NextPage } from 'next';

import { Page } from 'modules/common/layout';
import { Meta } from 'modules/common/meta';
import { LeadV2, Calculator, Help, Loan, Plus, How, Faq } from 'modules/home';

/**
 * Главная страница сайта.
 */
const Index: NextPage = () => {
  return (
    <Page layout="screen">
      <Meta
        title="Срочные онлайн-займы на карту"
        description="Взять займ онлайн от «Аполлон Займ». Нужен паспорт. Быстрое одобрение. До 30 000 рублей. Без залогов и поручителей."
      />
      <LeadV2>
        <Calculator />
      </LeadV2>
      <Help />
      <Loan />
      <Plus />
      <How />
      <Faq />
    </Page>
  );
};

export default Index;
