import { ComponentPropsWithoutRef, FC } from 'react';

import { Arrow } from 'modules/common/icons';

import { Root } from './Head.Root';
import { Title } from './Head.Title';
import { Icon } from './Head.Icon';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Флаг указывающий на состояние открытия заголовка.
   */
  isOpened?: boolean;
};

/**
 * Компонент отображающий текст и стрелку. Используется в аккордеоне.
 */
export const Head: FC<Props> = ({ children, isOpened = false, ...props }) => (
  <Root isOpened={isOpened} {...props}>
    <Title>{children}</Title>
    <Icon isOpened={isOpened}>
      <Arrow />
    </Icon>
  </Root>
);
