import { FC, ComponentPropsWithoutRef } from 'react';
import { useRouter } from 'next/router';

import { CalculatorService as MetricService } from 'modules/common/metrics';
import {
  Calculator as Base,
  CalculatorV2 as BaseV2,
  Store,
} from 'modules/calculator';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Base>;

/**
 * Отображает калькулятор, который должен быть расположен в публичной части
 * сайта. Такой калькулятор, при нажатии кнопки "Получить", выполняет
 * перенаправление пользователя на служебную страницу создания заявки на заём,
 * подставляя в неё выбранные параметры.
 */
export const Calculator: FC<Props> = ({ ...props }) => {
  const metricService = MetricService.use();
  const router = useRouter();
  const store = Store.use();

  const handleSubmit = () => {
    metricService.submitted();

    const href = store.getHref(router.query);
    router.push(href);
  };

  return (
    <>
      <BaseV2 {...props} onSubmit={handleSubmit} />
    </>
  );
};
