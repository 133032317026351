import { ComponentPropsWithoutRef, FC, useState, useCallback } from 'react';

import { SlideTransition } from 'modules/common/transition';

import { Root } from './Accordion.Root';
import { Head } from './Head';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заглавие.
   */
  title: string;

  /**
   * Флаг указывающий на состояние открытия.
   */
  isOpened?: boolean;
};

/**
 * Компонент отображающий аккордеон.
 */
export const Accordion: FC<Props> = ({
  title,
  isOpened = false,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(isOpened);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Root {...props} onClick={toggle}>
      <Head isOpened={isOpen}>{title}</Head>
      <SlideTransition visible={isOpen}>{children}</SlideTransition>
    </Root>
  );
};
