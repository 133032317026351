import { ComponentPropsWithoutRef, FC } from 'react';

import { Nowrap } from 'modules/common/ui';

import { Root } from './Card.Root';
import { Badge } from './Card.Badge';
import { Title } from './Card.Title';
import { Description } from './Card.Description';
import { Image } from './Card.Image';
import { LinkButton } from './Card.LinkButton';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Root> & {
  /**
   * Заголовок.
   */
  title: string;

  /**
   * Проценты.
   */
  badge: string;

  /**
   * URL изображения.
   */
  image: string;

  /**
   * URL изображения 2х.
   */
  image2x: string;

  /**
   * Описание.
   */
  description: string;

  /**
   * Ссылка на страницу создания заявки.
   */
  href: string;
};

/**
 * Компонент отображающий карточку займа.
 */
export const Card: FC<Props> = ({
  title,
  badge,
  image,
  image2x,
  description,
  href,
  ...props
}) => (
  <Root {...props}>
    <Title>
      <Nowrap>{title}</Nowrap>
    </Title>
    <Badge>
      <Nowrap>{badge}</Nowrap>
    </Badge>
    <Image src={image} srcSet={`${image2x} 2x`} alt={title} />
    <Description>
      <Nowrap>{description}</Nowrap>
    </Description>
    <LinkButton href={href} variant="filled">
      Выбрать
    </LinkButton>
  </Root>
);
