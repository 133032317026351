import styled from '@emotion/styled';

import { Section as Base } from '../Section';

/**
 * Компонент отображающий секцию блока на главной странице.
 */
export const Section = styled(Base)`
  padding-bottom: 9.375rem;
`;
