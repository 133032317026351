import styled from '@emotion/styled';

import { Container as Base } from 'modules/common/ui';

/**
 * Отображает компонент контейнера.
 */
export const Container = styled(Base)`
  display: flex;

  justify-content: center;
`;
